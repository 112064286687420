import { useLocation } from "react-router";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";


function MenuItems() {
  const { pathname } = useLocation();

  return (
    <Menu
      theme="light"
      mode="horizontal"
      style={{
        display: "flex",
        fontSize: "17px",
        fontWeight: "500",
        width: "100%",
        justifyContent: "center",
      }}
      defaultSelectedKeys={[pathname]}
    >
      <Menu.Item key="/nubi">
        <NavLink to="/nubi">NUBI</NavLink>
      </Menu.Item>
      <Menu.Item key="/sup">
        <NavLink to="/sup">SUP</NavLink>
      </Menu.Item>
      <Menu.Item key="/cuz">
        <NavLink to="/cuz">Zombie</NavLink>
      </Menu.Item>
      <Menu.Item key="/coffeehouse">
        <NavLink to="/coffeehouse">Coffee House</NavLink>
      </Menu.Item>
      <Menu.Item key="/1inch">
        <NavLink to="/1inch">Swap</NavLink>
      </Menu.Item>
      <Menu.Item key="/erc20balance">
        <NavLink to="/erc20balance">Balances</NavLink>
      </Menu.Item>
      <Menu.Item key="/erc20transfers">
        <NavLink to="/erc20transfers">Transfers</NavLink>
      </Menu.Item>
      <Menu.Item key="/nftBalance">
        <NavLink to="/nftBalance">NFTs</NavLink>
      </Menu.Item>
    </Menu>
  );
}

export default MenuItems;
