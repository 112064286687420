import { Card, Space, Timeline, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { ZOMBIE_ABI, ZOMBIE_ADDRESS } from "./ZombieContractInfo"
import { useWeb3ExecuteFunction, useWeb3Contract, useMoralis } from "react-moralis";
import ZombieMint from "./ZombieMint";
import { ApproveButton } from "../NUBI/ApproveButton";

const { Text } = Typography;
const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};

const InfectButton = ({fromID, targetID}) => {
  const { runContractFunction, contractResponse, error, isRunning, isLoading } = useWeb3Contract({
    abi: ZOMBIE_ABI,
    contractAddress: ZOMBIE_ADDRESS,
    functionName: "infect",
    params: {
      zombieID:fromID,
      targetID:targetID
    },
  });

  return (<div>
    <button onClick={() => runContractFunction()} disabled={isLoading}>Make Zombie</button>
  </div>);
};
const InfectCounterButton = ({fromID}) => {
  const { data, error, fetch, isFetching, isLoading } = useWeb3ExecuteFunction();

  const checkScoreOption = {
    abi: ZOMBIE_ABI,
    contractAddress: ZOMBIE_ADDRESS,
    functionName: "infectCounter",
    params: {"":fromID},
  };

  return (<div>
    <button onClick={() => fetch({params:checkScoreOption})} disabled={isLoading}>Check Score</button> Infection : {data} 
  </div>);
};

export default function ZombieApp({ isServerInfo }) {
  const [ fromId, setFromId ] = useState("");
  const [ targetId, setTargetId ] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [OpenSeaAssetPage, setOpenSeaAssetPage] = useState("https://opensea.io/collection/cursed-urban-zombie");
  const { data, error, fetch, isFetching, isLoading } = useWeb3ExecuteFunction();

  // get infect cost
  const getInfectCostOption = {
      abi: ZOMBIE_ABI,
      contractAddress: ZOMBIE_ADDRESS,
      functionName: "infectCost",
      params: {},
  };
  // get from infected
  const getFromInfectedOption = {
    abi: ZOMBIE_ABI,
    contractAddress: ZOMBIE_ADDRESS,
    functionName: "infected",
    params: {"":fromId},
  };
  // get target infected
  const getTargetInfectedOption = {
    abi: ZOMBIE_ABI,
    contractAddress: ZOMBIE_ADDRESS,
    functionName: "infected",
    params: {"":targetId},
  };
  // get totalSupply
  const getTotalSupplyOption = {
    abi: ZOMBIE_ABI,
    contractAddress: ZOMBIE_ADDRESS,
    functionName: "totalSupply",
    params: {},
  };


  const decimals = 1000000000000000000;

  useEffect(()=>{
      setTotalPrice(Math.round(data/decimals));
  }, [data, decimals]);

  useEffect(()=>{
      fetch({params:getInfectCostOption});
  }, [fromId, targetId]);

  const onChangeId = (e) => {
    let changedValue = e.target.value;

    if(changedValue < 0 ) changedValue = 1;
    else if(changedValue > 10000) changedValue = 10000;

    if(e.target.id == "from")
      setFromId(changedValue);
    else if (e.target.id == "target")
      setTargetId(changedValue);
      setOpenSeaAssetPage("https://opensea.io/assets/matic/"+ZOMBIE_ADDRESS+"/"+changedValue);
  }

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <ZombieMint></ZombieMint>
      <div>
        <Card
          style={{ marginTop: "10px", ...styles.card }}
          title={
            <>
              🦠 <Text strong>Infect other Zombie</Text>
            </>
          }
        >
          <Timeline mode="left" style={styles.timeline}>
          <Timeline.Item dot="🧟">
              <Text style={styles.text}>
                From ID # (your infected one)<form>
                  <input id="from" type="number" min="1" max="10000" placeholder="Here..." value={fromId} onChange={onChangeId}></input>
                </form>
              </Text>
            </Timeline.Item>
            <Timeline.Item dot="🎯">
              <Text style={styles.text}>
                Target ID # (not infected)<form>
                  <input id="target" type="number" min="1" max="10000" placeholder="Here..." value={targetId} onChange={onChangeId}></input>
                </form>
              </Text>
            </Timeline.Item>
            <Timeline.Item dot="🔍">
              <Text style={styles.text}>
                Check the price : {totalPrice} NUBI
              </Text>
            </Timeline.Item>
            <Timeline.Item dot="🔏">
              <Text style={styles.text}>
                Approve to use NUBI Coin.
              </Text> <ApproveButton spenderAddress={ZOMBIE_ADDRESS} approveAmount={totalPrice}></ApproveButton>
            </Timeline.Item>
            <Timeline.Item dot="🩸">
              <Text style={styles.text}>
                Infect it!
              </Text><InfectButton fromID={fromId} targetID={targetId}></InfectButton>
            </Timeline.Item>
            <Timeline.Item dot="🎖">
              <Text style={styles.text}>
                Check infection score
              </Text><InfectCounterButton fromID={fromId}></InfectCounterButton>
            </Timeline.Item>
            <Timeline.Item dot="🐳">
              <Text style={styles.text}>
                Click "Refresh Metadata" button on
              </Text> <a href={OpenSeaAssetPage} target="_blank">OpenSea</a>
            </Timeline.Item>
          </Timeline>
        </Card>
      </div>
    </div>
  );
}
