import { Card, Timeline, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useWeb3ExecuteFunction, useWeb3Contract } from "react-moralis";
import { ZOMBIE_ADDRESS, ZOMBIE_ABI } from "./ZombieContractInfo"
import { GetNUBIButton } from "../NUBI/GetNUBIButton";
import { ApproveButton } from "../NUBI/ApproveButton";

const { Text } = Typography;

const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};
const ZombieMintButton = ({mintAmount}) => {
    const { runContractFunction, contractResponse, error, isRunning, isLoading } = useWeb3Contract({
      abi: ZOMBIE_ABI,
      contractAddress: ZOMBIE_ADDRESS,
      functionName: "mint",
      params: {
        _mintAmount: mintAmount
      },
    });
  
    return (<div>
      <button onClick={() => runContractFunction()} disabled={isLoading}>Mint</button>
    </div>);
};

export default function ZombieMint() {
    const { data, error, fetch, isFetching, isLoading } = useWeb3ExecuteFunction();

    const [coffeeId, setCoffeeId] = useState(-1);
    // get price
    const getMintCost = {
        abi: ZOMBIE_ABI,
        contractAddress: ZOMBIE_ADDRESS,
        functionName: "cost",
        params: {}
    };

    const [totalPrice, setTotalPrice] = useState(0);
    const decimals = 1000000000000000000;
    const [ amount, setAmount ] = useState(1);

    useEffect(()=>{
        setTotalPrice(Math.round(data*amount/decimals));
    }, [data, amount, decimals]);

    useEffect(()=>{
        fetch({params:getMintCost});
    }, []);

    const onAmountChanged = (e) => {
        setAmount(e.target.value);
    };
    const onTypeChanged = (e) => {
        console.log(e.target.value);
        switch(e.target.value){
            case "hot":
                setCoffeeId(0);
                break;
            case "iced":
                setCoffeeId(1);
                break;
        }
    }

    return <Card
          style={styles.card}
          title={
            <>
              🧟 <Text strong>Mint a Cursed Urban Zombie NFT</Text>
            </>
          }
        >
          <Timeline mode="left" style={styles.timeline}>
            <Timeline.Item dot="🔢">
              <Text style={styles.text}>
                Select amount to mint : {amount}
              </Text>
              <form>
                <input onChange={onAmountChanged} type="range" id="coffee_amount" name="coffee_amount" required 
                min="1" max="20" value={amount}/>
              </form>
            </Timeline.Item>

            <Timeline.Item dot="🔍">
              <Text style={styles.text}>
                Check the price : {totalPrice} NUBI
              </Text>
            </Timeline.Item>
            <Timeline.Item dot="🔏">
              <Text style={styles.text}>
                (Optional) Get NUBI Coin with SUP.
              </Text> <GetNUBIButton></GetNUBIButton>
            </Timeline.Item>

            <Timeline.Item dot="🔏">
              <Text style={styles.text}>
                Approve to use NUBI Coin.
              </Text> <ApproveButton spenderAddress={ZOMBIE_ADDRESS} approveAmount={totalPrice}></ApproveButton>
            </Timeline.Item>

            <Timeline.Item dot="🏧">
              <Text style={styles.text}>
                Pay NUBI and get Zombies.
              </Text> <ZombieMintButton mintAmount={amount}></ZombieMintButton>
            </Timeline.Item>

            <Timeline.Item dot="🐳">
              <Text style={styles.text}>
                Check NFTs balance.
              </Text> <a href="https://opensea.io/collection/cursed-urban-zombie" target="_blank">Go To OpenSea</a>
            </Timeline.Item>
          </Timeline>
        </Card>
};