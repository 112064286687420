import { Card, Timeline, Typography } from "antd";
import React, { useEffect } from "react";
import { useWeb3ExecuteFunction } from "react-moralis";
import { COFFEE_ABI, COFFEE_ADDRESS } from "./CoffeeContractInfo"

const { Text } = Typography;

const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};

export default function CoffeeHouseMessages(){
    // get messages
    const { data, error, fetch, isFetching, isLoading } = useWeb3ExecuteFunction();
    const getMessagesOption = {
        abi: COFFEE_ABI,
        contractAddress: COFFEE_ADDRESS,
        functionName: "getMessages",
        params: {},
      };

    useEffect(() => {
      // load when it is initiailized.
        console.log("getMessages")
        fetch({params:getMessagesOption});
        return () => {
        };
    },[]);
    return <Card
            style={styles.card}
            title={
            <>
                📮 <Text strong>New messages from SUP holders</Text> <button onClick={()=>fetch({params:getMessagesOption})}>Read</button>
            </>
            }>{error && <Text error={error} />}
            {data && <Timeline mode="left" style={styles.timeline} disabled="{isFetching}">
            {data.map((msg, index) => (
                <Timeline.Item dot="📄" key={index}>
                <Text style={styles.text}>{msg}</Text>
                </Timeline.Item>
            ))}
            </Timeline>
            }</Card>;
};