import { Card, Space, Timeline, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { COFFEE_ABI, COFFEE_ADDRESS } from "./CoffeeContractInfo"
import CoffeeHouseMessages from "./CoffeeHouseMessages";
import { useWeb3ExecuteFunction, useWeb3Contract, useMoralis } from "react-moralis";
import CoffeeHouseMint from "./CoffeeHouseMint";

const { Text } = Typography;
const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};
const CheckBalances = ({setData}) => {
  const { account } = useMoralis();
  const { data, error, fetch, isFetching, isLoading } = useWeb3ExecuteFunction();
  setData(data);
  const checkBalancesOption = {
      abi: COFFEE_ABI,
      contractAddress: COFFEE_ADDRESS,
      functionName: "balanceOfBatch",
      params: {
        accounts:[account, account],
        ids:[1,2]
      },
    };

  return (<div>
    <button onClick={() => fetch({params:checkBalancesOption})} disabled={isLoading}>Check Balances</button>
    <Text style={styles.text}> ☕️Hot {data && data[0]} 🧊Iced {data && data[1]}</Text>
  </div>);
};
const DrinkButton = ({id, amount, message}) => {
  const { runContractFunction, contractResponse, error, isRunning, isLoading } = useWeb3Contract({
    abi: COFFEE_ABI,
    contractAddress: COFFEE_ADDRESS,
    functionName: "drink",
    params: {
      id:id,
      amount:amount,
      message:message
    },
  });

  return (<div>
    <button onClick={() => runContractFunction()} disabled={isLoading}>Drink!</button>
  </div>);
};

export default function CoffeeHouse({ isServerInfo }) {
  const [coffeeId, setCoffeeId] = useState(-1);
  const [balances, setBalances] = useState([0,0]);

  const [ amount, setAmount ] = useState(1);
  const [ maxAmount, setMaxAmount ] = useState(0);
  const [ message, setMessage ] = useState("");

  const onAmountChanged = (e) => {
    setAmount(e.target.value);
  };

  const onChangeMessage = (e) => {
    setMessage(e.target.value);
  }
  const onTypeChanged = (e) => {
      console.log(e.target.value);
      switch(e.target.value){
          case "hot":
              setCoffeeId(0);
              setMaxAmount(balances[0]);
              break;
          case "iced":
              setCoffeeId(1);
              setMaxAmount(balances[1]);
              break;
      }
  };
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <CoffeeHouseMessages></CoffeeHouseMessages>
      <div>
        <CoffeeHouseMint></CoffeeHouseMint>
        <Card
          style={{ marginTop: "10px", ...styles.card }}
          title={
            <>
              📝 <Text strong>Write your own message</Text>
            </>
          }
        >
          <Timeline mode="left" style={styles.timeline}>
            <Timeline.Item dot="🥤">
              <Text style={styles.text}>
                Get Super Urban Coffee NFTs
              </Text> <CheckBalances setData={setBalances}></CheckBalances>
            </Timeline.Item>
            <Timeline.Item dot="🧊">
              <Text style={styles.text}>
                Select type of Coffee
              </Text>
              <form>
                <select onChange={onTypeChanged} name="coffee_type">
                    <option value="none">Select</option>
                    <option value="hot">Hot Coffee</option>
                    <option value="iced">Iced Coffee</option>
                </select>
              </form>
            </Timeline.Item>
            <Timeline.Item dot="🔢">
              <Text style={styles.text}>
                Select amount of Coffee : {amount}
              </Text>
              <form>
                <input onChange={onAmountChanged} type="range" id="coffee_amount" name="coffee_amount" required 
                min="1" max={maxAmount} value={amount}/>
              </form>
            </Timeline.Item>
            <Timeline.Item dot="✏️">
              <Text style={styles.text}>
                Write a message<form>
                  <input type="text" maxLength="140" placeholder="Here..." value={message} onChange={onChangeMessage}></input>
                </form>
              </Text>
            </Timeline.Item>
            <Timeline.Item dot="☕️">
              <Text style={styles.text}>
                Drink it!
              </Text><DrinkButton id={coffeeId+1} amount={amount} message={message}></DrinkButton>
            </Timeline.Item>
            <Timeline.Item dot="👈">
              <Text style={styles.text}>
                Check your message in a few seconds.
              </Text>
            </Timeline.Item>
          </Timeline>
        </Card>
      </div>
    </div>
  );
}
