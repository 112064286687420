import { useMoralis } from "react-moralis";
import React from "react";
import { useWeb3Contract } from "react-moralis";
import { NUBI_ABI, NUBI_ADDRESS } from "./NUBIContractInfo";

export const ApproveButton = ({ spenderAddress, approveAmount }) => {
  const decimals = 18;
  const { Moralis } = useMoralis();

  console.log(approveAmount);
  const { runContractFunction, contractResponse, error, isRunning, isLoading } = useWeb3Contract({
    abi: NUBI_ABI,
    contractAddress: NUBI_ADDRESS,
    functionName: "approve",
    params: {
      spender: spenderAddress,
      amount: Moralis.Units.Token(approveAmount + 0.1, decimals)
    }
  });

  return (<div>
    <button onClick={() => runContractFunction()} disabled={isLoading}>Approve</button>
  </div>);
};
