import React from "react";
import { useWeb3Contract } from "react-moralis";
import { NUBI_ABI, NUBI_ADDRESS } from "./NUBIContractInfo";

export const GetNUBIButton = () => {
  const { runContractFunction, contractResponse, error, isRunning, isLoading } = useWeb3Contract({
    abi: NUBI_ABI,
    contractAddress: NUBI_ADDRESS,
    functionName: "mintWithNFT",
    params: {}
  });

  return (<div>
    <button onClick={() => runContractFunction()} disabled={isLoading}>Get NUBI</button>
  </div>);
};
