
import { useMoralis } from "react-moralis";
import { Card, Timeline, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useWeb3ExecuteFunction, useWeb3Contract } from "react-moralis";
import { NUBI_ABI, NUBI_ADDRESS, COFFEE_ABI, COFFEE_ADDRESS } from "./CoffeeContractInfo"
import BigNumber from "bignumber.js";

const { Text } = Typography;

const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};
const GetNUBIButton = () => {
    const { runContractFunction, contractResponse, error, isRunning, isLoading } = useWeb3Contract({
      abi: NUBI_ABI,
      contractAddress: NUBI_ADDRESS,
      functionName: "mintWithNFT",
      params: {},
    });
  
    return (<div>
      <button onClick={() => runContractFunction()} disabled={isLoading}>Get NUBI</button>
    </div>);
  };

const ApproveButton = ({approveAmount}) => {
    const decimals = 18;
    console.log("approve");
    const { Moralis } = useMoralis();
    const { runContractFunction, contractResponse, error, isRunning, isLoading } = useWeb3Contract({
      abi: NUBI_ABI,
      contractAddress: NUBI_ADDRESS,
      functionName: "approve",
      params: {
        spender: COFFEE_ADDRESS,
        amount: Moralis.Units.Token(approveAmount, decimals)
      },
    });
  
    return (<div>
      <button onClick={() => runContractFunction()} disabled={isLoading}>Approve</button>
    </div>);
  };

const MintButton = ({mintId, mintAmount}) => {
    console.log((mintId+1)+ ":" +mintAmount);
    const { authenticate, isAuthenticated, account, chainId, logout } = useMoralis();
    const { runContractFunction, contractResponse, error, isRunning, isLoading } = useWeb3Contract({
      abi: COFFEE_ABI,
      contractAddress: COFFEE_ADDRESS,
      functionName: "mint",
      params: {
        id: mintId+1,
        amount: mintAmount
      },
    });
  
    return (<div>
      <button onClick={() => runContractFunction()} disabled={isLoading}>Mint</button>
    </div>);
};

export default function CoffeeHouseMint() {
    const { data, error, fetch, isFetching, isLoading } = useWeb3ExecuteFunction();

    const [coffeeId, setCoffeeId] = useState(-1);
    // get price
    const getPriceOption = {
        abi: COFFEE_ABI,
        contractAddress: COFFEE_ADDRESS,
        functionName: "prices",
        params: {"":coffeeId},
    };

    const [totalPrice, setTotalPrice] = useState(0);
    const decimals = 1000000000000000000;
    const [ amount, setAmount ] = useState(1);

    useEffect(()=>{
        setTotalPrice(Math.round(data*amount/decimals));
    }, [data, amount, decimals]);

    useEffect(()=>{
        fetch({params:getPriceOption});
    }, [coffeeId]);

    const onAmountChanged = (e) => {
        setAmount(e.target.value);
    };
    const onTypeChanged = (e) => {
        console.log(e.target.value);
        switch(e.target.value){
            case "hot":
                setCoffeeId(0);
                break;
            case "iced":
                setCoffeeId(1);
                break;
        }
    }

    return <Card
          style={styles.card}
          title={
            <>
              🥤 <Text strong>Mint a Super Urban Coffee NFT</Text>
            </>
          }
        >
          <Timeline mode="left" style={styles.timeline}>
            <Timeline.Item dot="🧊">
              <Text style={styles.text}>
                Select type of Coffee.
              </Text>
              <form>
                <select onChange={onTypeChanged} name="coffee_type">
                    <option value="none">Select</option>
                    <option value="hot">Hot Coffee</option>
                    <option value="iced">Iced Coffee</option>
                </select>
              </form>
            </Timeline.Item>

            <Timeline.Item dot="🔢">
              <Text style={styles.text}>
                Select amount to mint : {amount}
              </Text>
              <form>
                <input onChange={onAmountChanged} type="range" id="coffee_amount" name="coffee_amount" required 
                min="1" max="10" value={amount}/>
              </form>
            </Timeline.Item>

            <Timeline.Item dot="🔍">
              <Text style={styles.text}>
                Check the price : {totalPrice} NUBI
              </Text>
            </Timeline.Item>
            <Timeline.Item dot="🔏">
              <Text style={styles.text}>
                (Optional) Get NUBI Coin with SUP.
              </Text> <GetNUBIButton></GetNUBIButton>
            </Timeline.Item>

            <Timeline.Item dot="🔏">
              <Text style={styles.text}>
                Approve to use NUBI Coin.
              </Text> <ApproveButton approveAmount={totalPrice}></ApproveButton>
            </Timeline.Item>

            <Timeline.Item dot="🏧">
              <Text style={styles.text}>
                Pay NUBI and get new Coffee NFT.
              </Text> <MintButton mintId={coffeeId} mintAmount={amount}></MintButton>
            </Timeline.Item>

            <Timeline.Item dot="🐳">
              <Text style={styles.text}>
                Check NFTs balance.
              </Text> <a href="https://opensea.io/collection/coffeehouse-for-sup" target="_blank">Go To OpenSea</a>
            </Timeline.Item>
          </Timeline>
        </Card>
};